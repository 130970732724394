import { computed, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

import { DELIVERY_QUERY, DELIVERY_SUPPLIER_PRODUCTS_QUERY } from './graphql';

export function useDeliveryData(deliveryId) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(
    DELIVERY_QUERY,
    () => ({ id: deliveryId.value }),
    () => ({
      enabled: !!deliveryId.value,
    })
  );
  const delivery = computed(() => result.value?.delivery);
  const variables = ref({ deliveryId });
  const loading = useLoading(queryLoading, variables);

  const { result: productsResult } = useQuery(
    DELIVERY_SUPPLIER_PRODUCTS_QUERY,
    () => ({ supplierId: delivery.value?.supplier.id }),
    () => ({
      enabled: !!delivery.value,
    })
  );
  const products = computed(() => (productsResult.value ? productsResult.value.productsOldNew?.nodes ?? [] : []));

  onError((err) => {
    console.error('useDeliveryData', err);
    error();
  });

  return {
    delivery,
    supplierProducts: products,
    loading,
    refetch,
  };
}

import { gql } from '@apollo/client/core';

export const ORDER_QUERY = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      businessId
      supplierId
      date
      discountRate
      netAmount: amount
      task {
        id
        completedAt
      }
      eventReferences {
        reference
        documentId
        document {
          id
          documentNumber
          type
        }
      }
      differences {
        quantityAmount
        pricingAmount
        productIndex
        customerItemId
        supplierItemId
        customerOrderId
        supplierOrderId
        netPrice {
          customerValue
          supplierValue
          solved
        }
        product {
          id
          name
          sku
          references {
            name
            sku
          }
          associatedProducts {
            quantity
            product {
              id
              name
              sku
            }
          }
        }
        amount
        price {
          customerValue
          supplierValue
          solved
        }
        quantity {
          customerValue
          supplierValue
          solved
        }
        discount {
          customerValue
          supplierValue
          solved
        }
      }
      source {
        document: documentNew2 {
          id
          documentNumber
          type
        }
      }
      products {
        id
        type
        price
        quantity
        discount
        netPrice
        totalAmount
        orderDiscountAmount
        associatedTo
        source {
          price {
            type
            additionalInfo {
              priceListDate
            }
            user {
              profile {
                firstName
                lastName
              }
            }
            term {
              id
              type
              pricingMethod
              rewardValue
              price
              priceIndexName
              priceListId
              updatedAt
            }
            document: documentNew2 {
              documentNumber
              type
            }
          }
          discount {
            type
            user {
              profile {
                firstName
                lastName
              }
            }
            term {
              id
              type
              pricingMethod
              rewardValue
              price
              priceIndexName
              priceListId
              updatedAt
            }
            document: documentNew2 {
              documentNumber
              type
            }
          }
        }
        product {
          id
          name
          sku
          references {
            name
            sku
          }
        }
      }
      supplier {
        id
        name
      }
    }
  }
`;

export const AGREEMENTS_QUERY = gql`
  query retrieveAgreements($supplierId: ID, $businessId: ID, $activeAt: Date) {
    agreements(supplierId: $supplierId, businessId: $businessId, activeAt: $activeAt) {
      id
      termIds
    }
  }
`;

export const ORDER_DIFFERENCE_PDF_MUTATION = gql`
  mutation OrderDifferencePdf($template: String!, $data: JSONObject!) {
    pdfGenerate(template: $template, data: $data) {
      filename
      url
    }
  }
`;

export const ORDER_SUPPLIER_PRODUCTS_QUERY = gql`
  query Products($supplierId: ID!) {
    productsOldNew(businessId: $supplierId) {
      nodes {
        id
        sku
        name
        associatedProducts {
          productId
          quantity
        }
      }
    }
  }
`;

export const ORDER_ITEM_QUERY = gql`
  query getOrderItemById($orderId: ID!, $itemId: ID!) {
    orderItem(orderId: $orderId, itemId: $itemId) {
      productId
      quantity
      price
      discount
      netPrice
      totalAmount
    }
  }
`;

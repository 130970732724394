import { gql } from '@apollo/client/core';

export const BILLING_QUERY = gql`
  query billing($id: ID!) {
    billing(id: $id) {
      id
      businessId
      type
      supplier {
        id
        name
      }
      eventReferences {
        allocationNumber
        document {
          id
          documentNumber
          type
        }
      }
      imbalances(resolved: "all") {
        billedAmounts {
          id
          amount
        }
        orderedAmounts {
          id
          amount
        }
        resolved
      }
      netAmount
      taxAmount
      totalAmount
      date
      deliveryRefs {
        delivery {
          id
          date
          orderIds
          source {
            document: documentNew2 {
              id
              type
              documentNumber
            }
          }
          eventReferences {
            documentId
            reference
            document: documentNew2 {
              id
              type
              documentNumber
            }
          }
          products {
            productId
            quantity
          }
        }
        externalRef
        date
      }
      orderLinks {
        order {
          id
          date
          amount
          netAmount
          products {
            productId
            quantity
          }
          eventReferences {
            documentId
            reference
            document {
              id
              type
              documentNumber
            }
          }
        }
        externalRef
        amount
      }
      allocationRequired
    }
  }
`;

export const BILLINGS_QUERY = gql`
  query getRelatedBillings($businessId: ID!, $ids: [ID], $orderId: ID, $eventRef: ID) {
    billings(businessId: $businessId, ids: $ids, orderId: $orderId, eventRef: $eventRef) {
      nodes {
        id
        eventReferences {
          document {
            id
            documentNumber
            type
          }
        }
        orderLinks {
          externalRef
          amount
          order {
            id
          }
        }
      }
    }
  }
`;

export const getDifferencesSpecification = (differences) => {
  const differencesObj = differences.length
    ? differences.reduce(
        (prev, curr) => {
          if (curr.netPrice) {
            curr.netPrice.solved ? prev.solvedPriceDiff.push(curr.amount) : prev.unsolvedPriceDiff.push(curr.amount);
          } else if (curr.quantity) {
            curr.quantity.solved
              ? prev.solvedQuantityDiff.push(curr.quantityAmount)
              : prev.unsolvedQuantityDiff.push(curr.quantityAmount);
          } else if (curr.discount) {
            curr.discount.solved
              ? prev.solvedPriceDiff.push(curr.pricingAmount)
              : prev.unsolvedPriceDiff.push(curr.pricingAmount);
          }
          return prev;
        },
        { solvedPriceDiff: [], solvedQuantityDiff: [], unsolvedPriceDiff: [], unsolvedQuantityDiff: [] }
      )
    : { solvedPriceDiff: [], solvedQuantityDiff: [], unsolvedPriceDiff: [], unsolvedQuantityDiff: [] };

  let resultStatus = DIFFERENCES_STATES.NO_DIFFERENCES;

  if (
    (differencesObj.unsolvedPriceDiff.length || differencesObj.unsolvedQuantityDiff.length) &&
    (differencesObj.solvedPriceDiff.length || differencesObj.solvedQuantityDiff.length)
  ) {
    resultStatus = DIFFERENCES_STATES.PARTLY_SOLVED;
  } else if (differencesObj.unsolvedPriceDiff.length || differencesObj.unsolvedQuantityDiff.length) {
    resultStatus = DIFFERENCES_STATES.UNSOLVED;
  } else if (differencesObj.solvedPriceDiff.length || differencesObj.solvedQuantityDiff.length) {
    resultStatus = DIFFERENCES_STATES.SOLVED;
  }

  differencesObj.resultStatus = resultStatus;

  let amountStatus = DIFFERENCES_STATES.NO_DIFFERENCES;

  if (differencesObj.unsolvedPriceDiff.length && differencesObj.solvedPriceDiff.length) {
    amountStatus = DIFFERENCES_STATES.PARTLY_SOLVED;
  } else if (differencesObj.unsolvedPriceDiff.length) {
    amountStatus = DIFFERENCES_STATES.UNSOLVED;
  } else if (differencesObj.solvedPriceDiff.length) {
    amountStatus = DIFFERENCES_STATES.SOLVED;
  }

  differencesObj.amountStatus = amountStatus;

  let quantityStatus = DIFFERENCES_STATES.NO_DIFFERENCES;

  if (differencesObj.unsolvedQuantityDiff.length && differencesObj.solvedQuantityDiff.length) {
    quantityStatus = DIFFERENCES_STATES.PARTLY_SOLVED;
  } else if (differencesObj.unsolvedQuantityDiff.length) {
    quantityStatus = DIFFERENCES_STATES.UNSOLVED;
  } else if (differencesObj.solvedQuantityDiff.length) {
    quantityStatus = DIFFERENCES_STATES.SOLVED;
  }

  differencesObj.quantityStatus = quantityStatus;

  return differencesObj;
};

export const DIFFERENCES_STATES = {
  NO_DIFFERENCES: 'noDifferences',
  PARTLY_SOLVED: 'partlySolved',
  UNSOLVED: 'unsolved',
  SOLVED: 'solved',
};

import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

const onDoneSubscribedCallbacks = [];

export const useCreateOrderFromDocument = (options) => {
  const { mutate: createOrderFromDocument, loading, onDone } = useMutation(MUTATION, options);
  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });
  const onDoneCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    createOrderFromDocument,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
  };
};

export const MUTATION = gql`
  mutation createOrderFromDocument($documentId: ID!) {
    orderCreateFromDocument(documentId: $documentId) {
      id
      date
      amount
      netAmount
      products {
        productId
        quantity
      }
      eventReferences {
        documentId
        reference
        document {
          id
          type
          documentNumber
        }
      }
    }
  }
`;

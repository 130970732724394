import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';

import { BILLINGS_QUERY } from './graphql';

export const useBillingsByIds = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(BILLINGS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.ids.length,
  }));
  const billings = computed(() => result.value?.billings?.nodes ?? []);

  onError((err) => {
    console.error('eventMapModal - billing - useBillingsByIds', err);
    error();
  });

  return {
    billings,
    loading,
  };
};

export const useBillings = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(BILLINGS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && (!!variables.value.orderId || !!variables.value.eventRef),
  }));
  const billings = computed(() => result.value?.billings?.nodes ?? []);

  onError((err) => {
    console.error('eventMapModal - billing - useBillings', err);
    error();
  });

  return {
    billings,
    loading,
  };
};

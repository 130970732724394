import { gql } from '@apollo/client/core';

export const DELIVERY_QUERY = gql`
  query delivery($id: ID!) {
    delivery(id: $id) {
      id
      date
      businessId
      supplierId
      orderIds
      diffs {
        product {
          id
          sku
          name
        }
        customerValue
        supplierValue
        resolved
        items {
          product {
            id
          }
          customerValue
          supplierValue
          resolved
        }
      }
      imbalances {
        productId
      }
      eventReferences {
        reference
        documentId
        document: documentNew2 {
          id
          documentNumber
          type
        }
      }
      source {
        document: documentNew2 {
          id
          documentNumber
          type
        }
      }
      products {
        id
        quantity
        product {
          id
          name
          sku
        }
        items {
          id
          quantity
          product {
            id
            name
            sku
          }
        }
      }
      supplier {
        id
        name
      }
    }
  }
`;

export const DELIVERY_SUPPLIER_PRODUCTS_QUERY = gql`
  query Products($supplierId: ID!) {
    productsOldNew(businessId: $supplierId) {
      nodes {
        id
        sku
        name
        associatedProducts {
          productId
          quantity
        }
      }
    }
  }
`;

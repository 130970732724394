import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

const onDoneSubscribedCallbacksNew = [];

export const useCreateDeliveryFromDocument = (options) => {
  const { mutate: createDeliveryFromDocument, loading, onDone } = useMutation(MUTATION, options);
  onDone((result) => {
    onDoneSubscribedCallbacksNew.forEach((cb) => cb(result));
  });
  const onDoneCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacksNew.splice(onDoneSubscribedCallbacksNew.indexOf(cb), 1));
  });

  return {
    createDeliveryFromDocument,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacksNew.push(callback);
    },
  };
};

export const MUTATION = gql`
  mutation createDeliveryFromDocument($documentId: ID!) {
    deliveryCreateFromDocument(documentId: $documentId) {
      id
      date
      orderIds
      source {
        document: documentNew2 {
          id
          type
          documentNumber
        }
      }
      eventReferences {
        documentId
        reference
        document: documentNew2 {
          id
          type
          documentNumber
        }
      }
      products {
        productId
        quantity
      }
    }
  }
`;

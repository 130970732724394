import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useOrderDifferences(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(ORDER_DIFFERENCES_QUERY, variables, () => ({
    enabled: !!variables.value.customerId,
  }));
  const orderDifferences = computed(() => result.value?.orderDifferencesNew?.nodes ?? []);

  onError((err) => {
    console.error('eventMapModal - useOrderDifferences', err);
    error();
  });

  return {
    orderDifferences,
    loading,
    refetch,
  };
}

const ORDER_DIFFERENCES_QUERY = gql`
  query orderDifferences($customerId: ID) {
    orderDifferences(customerId: $customerId) {
      nodes {
        id
        eventReferences {
          documentId
        }
      }
    }
  }
`;

import { gql } from '@apollo/client/core';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { onUnmounted, ref, computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export function usePaymentData(id) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(PAYMENT_QUERY, () => ({ id }));

  const payment = computed(() => result.value?.payment ?? {});
  const variables = ref({ id });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('usePaymentData', err);
    error();
  });

  return {
    payment,
    loading,
    refetch,
  };
}

export const PAYMENT_QUERY = gql`
  query payment($id: ID!) {
    payment(id: $id) {
      id
      type
      supplierId
      businessId
      supplier {
        id
        name
      }
      requestedDate
      date
      type
      amount
      isRefund
      transactions {
        id
        paymentMethod
        reference
        type
        cardOwner
        signedBy
        number
        date
        instructionNumber
        sourceBankAccount {
          bankNumber
          branchNumber
          accountNumber
          accountName
        }
        destinationBankAccount {
          bankNumber
          branchNumber
          accountNumber
          accountName
        }
      }
      billingLinks {
        amount
        date
        reference
        billing {
          id
          date
          totalAmount
          source {
            document: documentNew2 {
              id
              documentNumber
              type
            }
          }
        }
      }
      eventReferences {
        reference
        documentId
      }
    }
  }
`;

const onDoneSubscribedCallbacks = [];
const onErrorSubscribedCallbacks = [];

export const usePaymentPatch = () => {
  const { mutate, loading, onDone, onError } = useMutation(PAYMENT_PATCH_MUTATION);

  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
    onErrorCallbacks.forEach((cb) => onErrorSubscribedCallbacks.splice(onErrorSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    patchPayment: mutate,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onErrorSubscribedCallbacks.push(callback);
    },
  };
};

const PAYMENT_PATCH_MUTATION = gql`
  mutation patchPayment($id: ID, $patchParams: PaymentPatchInput!) {
    paymentPatch(id: $id, patchParams: $patchParams) {
      id
      date
      transactions {
        id
        paymentMethod
        reference
        type
        cardOwner
        signedBy
        number
        date
        instructionNumber
        sourceBankAccount {
          bankNumber
          branchNumber
          accountNumber
          accountName
        }
        destinationBankAccount {
          bankNumber
          branchNumber
          accountNumber
          accountName
        }
      }
    }
  }
`;

const UNBILLED_PAYMENTS_QUERY = gql`
  query unbilledPayments($businessId: ID!, $supplierId: ID, $paymentId: ID) {
    unbilledPayments(businessId: $businessId, supplierId: $supplierId, paymentId: $paymentId) {
      nodes {
        date
        paymentId
        supplierId
        paidAmount
        billedAmount
        createdBy {
          profile {
            firstName
            lastName
          }
        }
        createdAt
        billingLinks {
          amount
          billingId
          date
        }
        transactions {
          id
          paymentMethod
        }
      }
    }
  }
`;

export const useUnbilledPayments = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(UNBILLED_PAYMENTS_QUERY, variables, () => ({
    enabled: !!variables.value?.businessId,
  }));
  const unbilledPayments = computed(() => result.value?.unbilledPayments?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useUnbilledPayments', err);
    error();
  });

  return { unbilledPayments, loading, refetch };
};

const onDeleteDoneSubscribedCallbacks = [];
const onDeleteErrorSubscribedCallbacks = [];

export const usePaymentDelete = () => {
  const { mutate: deletePayment, loading, onDone, onError } = useMutation(PAYMENT_DELETE_MUTATION);

  onDone((result) => {
    onDeleteDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onDeleteErrorSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) =>
      onDeleteDoneSubscribedCallbacks.splice(onDeleteDoneSubscribedCallbacks.indexOf(cb), 1)
    );
    onErrorCallbacks.forEach((cb) =>
      onDeleteErrorSubscribedCallbacks.splice(onDeleteErrorSubscribedCallbacks.indexOf(cb), 1)
    );
  });

  return {
    deletePayment,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDeleteDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onDeleteErrorSubscribedCallbacks.push(callback);
    },
  };
};

const PAYMENT_DELETE_MUTATION = gql`
  mutation paymentDelete($id: ID!, $reason: PaymentDeleteReason!, $comment: String!) {
    paymentDelete(id: $id, reason: $reason, comment: $comment)
  }
`;

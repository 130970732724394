import { clone, isNil } from 'ramda';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

import { ORDER_QUERY, ORDER_SUPPLIER_PRODUCTS_QUERY } from './graphql';

export function useOrderData(orderId) {
  const { error } = useNotification();

  const variables = computed(() => ({ id: orderId.value }));

  const { result, loading, onError, refetch } = useQuery(ORDER_QUERY, variables, () => ({
    enabled: !!orderId.value,
  }));

  const calcTotal = (q, p, d) => (!isNil(p) ? q * p * (1 - d / 100) : null);

  const order = computed(() => {
    if (!result.value) return;
    const orderClone = clone(result.value.order);
    orderClone.products = orderClone.products.map((item, index) => {
      const totalAmount = calcTotal(item.quantity, item.price, item.discount);
      return {
        ...item,
        difference: orderClone.differences.find((difference) => difference.productIndex === index),
        totalAmount,
        orderDate: new Date(item.orderDate),
      };
    });

    return { ...orderClone, date: new Date(orderClone.date) };
  });

  const { result: productsResult } = useQuery(
    ORDER_SUPPLIER_PRODUCTS_QUERY,
    () => ({ supplierId: order.value?.supplier.id }),
    () => ({
      enabled: !!order.value,
    })
  );
  const supplierProducts = computed(() =>
    productsResult.value ? productsResult.value.productsOldNew?.nodes ?? [] : []
  );

  onError((err) => {
    console.error('useOrderData', err);
    error();
  });

  return {
    calcTotal,
    order,
    supplierProducts,
    loading,
    refetch,
  };
}

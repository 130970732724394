import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { BILLING_QUERY } from './graphql';
import { useCreateOrderFromDocument, useCreateDeliveryFromDocument } from './';

import { useNotification } from '@/modules/core';

export function useBillingData(billingId) {
  const { error } = useNotification();

  const variables = computed(() => ({ id: billingId.value }));

  const { result, loading, refetch, onError } = useQuery(BILLING_QUERY, variables, () => ({
    enabled: !!billingId.value,
  }));

  const billing = computed(() => result.value?.billing);

  const { createOrderFromDocument } = useCreateOrderFromDocument({
    update: (cache, { data: { orderCreateFromDocument: order } }) => {
      cache.modify({
        id: cache.identify({
          __typename: 'Billing',
          id: billingId.value,
        }),
        fields: {
          orderLinks() {
            return [order];
          },
        },
      });
    },
  });
  const { createDeliveryFromDocument } = useCreateDeliveryFromDocument({
    update: (cache, { data: { deliveryCreateFromDocument: delivery } }) => {
      cache.modify({
        id: cache.identify({
          __typename: 'Billing',
          id: billingId.value,
        }),
        fields: {
          orderLinks() {
            return [delivery];
          },
        },
      });
    },
  });

  onError((err) => {
    console.error('useBillingData', err);
    error();
  });

  return {
    billing,
    refetch,
    loading,
    createOrderFromDocument,
    createDeliveryFromDocument,
  };
}
